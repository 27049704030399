import {
  execAfterDomContentLoaded,
  safeGetLocalStorage,
  safeSetLocalStorage,
  sleep,
  unawaited,
} from "../../util";

export const storageMusicKey = "musicState";

let elMap: {
  pageHeader: HTMLElement;
  musicEl: HTMLAudioElement;
  soundEl: HTMLElement;
  soundOnEl: HTMLElement;
  soundOffEl: HTMLSelectElement;
};

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    pageHeader: document.getElementById("page-header"),
    musicEl: document.getElementById("music-el") as HTMLAudioElement,
    soundEl: document.getElementById("sound-btn"),
    soundOnEl: document.querySelector("#sound-btn > img:first-of-type"),
    soundOffEl: document.querySelector("#sound-btn > img:last-of-type"),
  };

  elMap.soundEl.addEventListener("click", onSoundElClick);

  document.addEventListener("scroll", () => {
    if (document.scrollingElement.scrollTop > window.innerHeight) {
      elMap.pageHeader.classList.add("visibility-background");
    } else {
      elMap.pageHeader.classList.remove("visibility-background");
    }
  });
}

function onSoundElClick() {
  if (elMap.musicEl.paused) {
    unawaited(elMap.musicEl.play());
  } else {
    elMap.musicEl.pause();
  }
}

export async function beginMusicPlayback() {
  elMap.musicEl.addEventListener("play", () => {
    elMap.soundOffEl.setAttribute("hidden", "true");
    elMap.soundOnEl.removeAttribute("hidden");
    safeSetLocalStorage(storageMusicKey, true);
  });

  elMap.musicEl.addEventListener("pause", () => {
    elMap.soundOnEl.setAttribute("hidden", "true");
    elMap.soundOffEl.removeAttribute("hidden");
    safeSetLocalStorage(storageMusicKey, false);
  });

  if (safeGetLocalStorage(storageMusicKey) !== false) {
    elMap.musicEl.volume = 0;
    if (elMap.musicEl.paused) {
      await elMap.musicEl.play();
    }
    while (elMap.musicEl.volume < 1) {
      try {
        elMap.musicEl.volume += 0.01;
      } catch {
        elMap.musicEl.volume = 1;
      }

      await sleep(10);
    }
  }
}
